<template>
  <div>
    <div class="banner">
      <div class="banner-content">
        <p class="banner-title">至灿集团</p>
        <p class="banner-info">四川省成都市锦江区IFS国际金融中心2号楼38楼</p>
        <p class="banner-info">联系电话：(028)- 8736-3916</p>
      </div>
    </div>
  </div>
  <div class="zone">
    <div class="area-title">
      <p class="title">人才培养</p>
    </div>
    <div class="job">
      <div>
        <div class="job-bg">

        </div>
        <div class="job-info">
          <p class="job-info--title">坚持【以人为本】的方针路线</p>
          <p class="job-info--sub_title">培养与发展，信任与尊重</p>
          <p class="job-info--item">
            <span>职能管理</span>
            <span>行政人事、招聘、培训、财务出纳...</span>
          </p>
          <p class="job-info--item">
            <span>品牌发展</span>
            <span>品牌宣传、文案策划、平面设计、运营...</span>
          </p>
          <p class="job-info--item">
            <span>销售运营</span>
            <span>销售、销售客服、大区经理、客户发展...</span>
          </p>
          <p class="job-info--item">
            <span>仓储供应</span>
            <span>采购、产品、订货、外贸、品控、食品研发...</span>
          </p>
        </div>
      </div>

      <!-- <div class="job-line">

      </div> -->
    </div>
  </div>
  <div class="zone join">
    <div class="area-title">
      <p class="title">加入我们</p>
    </div>
    <el-table
      class="job-table"
      :data="tableData"
    >
      <el-table-column
        prop="position"
        label="岗位名称"
        min-width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="experience"
        label="工作经验"
        min-width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="count"
        label="招聘人数"
        min-width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="education"
        label="最低学历"
        min-width="120"
        align="center"
      >
      </el-table-column>
    </el-table>
    <div class="join-btns">
      <span class="join-btn">更多职位敬请期待</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  components: {

  },
  data() {
    return {
      tableData: [
        {
          position: '销售',
          experience: '不限年',
          count: '20',
          education: '大专'
        },
        {
          position: '采购',
          experience: '不限年',
          count: '20',
          education: '大专'
        },
        {
          position: '客户发展',
          experience: '不限年',
          count: '20',
          education: '大专'
        },
        {
          position: '产品经理',
          experience: '不限年',
          count: '4',
          education: '大专'
        },
        {
          position: '文案策划',
          experience: '不限年',
          count: '2',
          education: '大专'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 36.5vw;
  background-image: url('../../../assets/images/join-job/ys/banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  .banner-content {
    position: absolute;
    left: 44.5vw;
    bottom: 14vw;
    color: #333333;
    width: 28.2rem;
    padding: 1.5rem;
    background-color: #fdfdfd;
    .banner-title {
      font-size: 2.4rem;
      line-height: 3.5rem;
      font-weight: bold;
    }
    .banner-info {
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }
}
.zone {
  padding: 4.9rem 0 0;
}
.job {
  width: 65vw;
  margin: 4.5vw auto 0;
  .job-bg {
    vertical-align: bottom;
    display: inline-block;
    width: 30vw;
    height: 24.4vw;
    background-image: url('../../../assets/images/join-job/v1/bg1.png');
    background-size: 100% 105%;
    background-repeat: no-repeat;
  }
  .job-info {
    vertical-align: bottom;
    display: inline-block;
    width: calc(65vw - 30vw);
    height: 24.4vw;
    background-color: #ffffff;
    padding: 2.4vw 0 0 5.2vw;
    text-align: left;
    color: #333333;
    box-sizing: border-box;
    overflow: hidden;
    // background-image: url('../../../assets/images/join-job/v1/bg2.png');
    background-size: 100% 105%;
    background-repeat: no-repeat;
    .job-info--title {
      font-size: 1.56vw;
      font-weight: bold;
      margin-bottom: 1.2vw;
    }
    .job-info--sub_title {
      font-size: 1.145vw;
      font-weight: bold;
      margin-bottom: 2vw;
    }
    .job-info--item {
      font-size: 1.145vw;
      font-weight: 500;
      margin-bottom: 0.5vw;
      max-width: 30vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 3vw;
      border-bottom: 1px solid #bebebe;
      span {
        &:first-child {
          font-size: 1.04vw;
          font-weight: 500;
          margin-right: 2.34vw;
        }
        &:last-child {
          max-width: 18.5vw;
          font-size: 0.94vw;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .job-line {
    width: 100%;
    height: 4.1vw;
    background-color: rgb(196, 0, 0);
  }
}

.join {
  .join-btns {
    width: 43rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 4.3rem;
    margin-bottom: 6.6rem;
    .join-btn {
      font-size: 2rem;
      color: #868686;
      // background-color: #e61d0e;
      padding: 1rem 4rem;
      // cursor: pointer;
    }
  }
}
.job-table {
  margin: 0 auto;
  margin-top: 9rem;
  margin-bottom: 3.3rem;
  width: 65.3vw;
}
.el-table ::v-deep .el-table__header-wrapper th {
  background-color: rgb(196, 0, 0);
  color: #ffffff;
  height: 8rem;
  font-size: 2.4rem;
}
.el-table ::v-deep .el-table__body-wrapper tr {
  color: #333333;
  height: 6rem;
  font-size: 1.8rem;
}
.el-table ::v-deep .el-table__body-wrapper td {
  border-bottom: 1px solid #d1d1d1;
}
.area-title {
  text-align: center;
  .title {
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    &::before {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 10rem;
      height: 0.4rem;
      background-color: #e61d0e;
      border-radius: 0.2rem;
    }
  }
}
</style>
